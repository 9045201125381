<template>
	<div>
		<div class="app-heading pd:md">
			<div v-t="'tnc'" class="app-title"></div>
		</div>

		<section class="pd:md">
			<ol class="info-list">
				<li>
					{{ appName }} is a mobile game service where subscribers have unlimited access and able to enjoy playing a wide variety of games.
					You acknowledge and agree that you are only permitted to use the {{ appName }} service as expressly set out in these Terms and Conditions. You will be deemed to have accepted these terms and conditions by using this website, regardless of whether or not you register as a subscriber. If you do not agree to these terms and conditions you must not use this website.
				</li>

				<li>
					By using the website, you represent, undertake and warrant that you have the right, authority and capacity to agree and to comply with all of the Terms and Conditions.
				</li>

				<li>
					You shall:

					<ol>
						<li>
							ensure the compatibility of your mobile device with {{ appName }}'s system upon subscription. In the event that changes and upgrades are introduced to {{ appName }}'s system, you shall be responsible to ensure the continued compatibility of your mobile device and you shall have no claim whatsoever against Telco provider or {{ appName }} arising therefrom.
						</li>

						<li>
							comply with all notice or instruction given {{ appName }} & telco provider from time to time in relation to the use of the service.
						</li>

						<li>
							be of age 18 years and above or have received permission from your parents or person who is authorized to pay your bill, in order to make use of this service.
						</li>

						<li>
							be responsible for all equipment and software necessary to use the {{ appName }} service and also for the security and integrity of all information and data transmitted, disclosed and / or obtained through the use of the service.
						</li>

						<li>
							be responsible for all usage of and charges for the {{ appName }} service including but not limited to payment of all the charges and any other related charges due to {{ appName }} pursuant to these Terms and Conditions.
						</li>

						<li>
							be aware that your subscription will automatically renew as long as you are subscribed and will only be unsubscribe from {{ appName }} if you do so
						</li>

						<li>
							be allowed to unsubscribe from {{ appName }}, at any time (Refer to <router-link :to="'/faq'" class="app-link" v-t="'faq'"></router-link> for instructions)
						</li>

						<li>
							be fully responsible for any data transmitted or broadcasted by you or persons using your Mobile Device. Any information and content posted by you or persons using your mobile device in the website can and shall be used by {{ appName }} in any way it so requires without any further claims from you of the ownership or rights of the materials posted; The company have the absolute rights ownership perpetually to manage all data, text, information, images, graphics, photos, profiles, audio and video clips, sounds, musical works, links and other content or materials that is submitted, posted or displayed including but not limited to {{ appName }}.
						</li>

						<li>
							comply with all applicable laws relating to the Service, including its subsidiary legislation, other acts, statutes, by-laws, rules and regulations issued by relevant government and regulatory agencies which may be amended from time to time.
						</li>

						<li>
							take all reasonable steps to prevent fraudulent, improper or illegal use of the service such as the usage of mobile numbers other than your won to access the service via the login page.
						</li>

						<li>
							cease to utilise the {{ appName }} service or any part thereof for such period as may be required by {{ appName }}.
						</li>

						<li>
							indemnify and shall keep indemnified {{ appName }} and the company from any loss, damage, liability or expense, arising from any claims for libel, invasion of privacy, infringement of copyright, patent, breach of confidence or privilege or breach of any law or regulation whatsoever arising from the content transmitted, received or stored via the service or part thereof and for all other claims arising out of any act or omission of your or any unauthorised use or exploitation of the services or part thereof.
						</li>
					</ol>
				</li>

				<li>
					You shall not use the service:

					<ol>
						<li>
							to cause embarrassment, distress, annoyance, irritation, harassment, inconvenience, anxiety or nuisance to any person.
						</li>

						<li>
							to cause excessive or disproportionate load on {{ appName }}'s system.
						</li>

						<li>
							for any unlawful purposes including but not limited to vice, gambling or other criminal purpose whatsoever or for sending to or receiving from any person any message which is offensive on moral, religious, communal or political grounds, or is abusive, defamatory or of an indecent, obscene or menacing character.
						</li>

						<li>
							for any purpose which is against public interest, public order or national harmony.
						</li>

						<li>
							to use, permit or cause to be used the {{ appName }} service improperly or for any activities which breach any laws, infringe a third party's rights, or breach any directives, content requirements or codes promulgated by any relevant authority including activities which will require {{ appName }} service to take remedial action under any applicable industry code or in a way interferes with other users or defames, harasses, menaces, restricts or inhibits any other use from using or enjoying the {{ appName }} service or the internet.
						</li>

						<li>
							to transmit or post any content that contains any harmful, damaging or destructive programs.
						</li>

						<li>
							to receive, access or transmit any content which is obscene, pornographic, threatening, racist, menacing, offensive, defamatory, in breach of confidence, in breach of any intellectual property right (including copyright) or otherwise objectionable or unlawful.
						</li>

						<li>
							to circumvent user authentication or security of any host, network or account (referred to as "cracking", or "hacking") not interfere with service to any user, host or network (referred to as "denial of service attacks") not copy any pages or register identical keywords with search engines to mislead other users into thinking that they are reading {{ appName }} legitimate web pages (referred to as "page-jacking"), or use the service for any other unlawful or objectionable conduct. Users who violate systems or network security may incur criminal or civil liability, and {{ appName }} will at its absolute discretion fully co-operate with investigations of suspected criminal violations, violation of systems or network security under the leadership of law enforcement or relevant authorities.
						</li>

						<li>
							use the service to advertise or offer to sell any goods or services for any commercial purpose without {{ appName }}'s written consent.
						</li>

						<li>
							hack into, make excessive traffic demands, deliver or forward chain letters, "junk mail" or "spam" or any kind, surveys, contests, pyramid schemes or otherwise engage in any other behaviour intended to inhibit other users from using and enjoying the service or any other web site, or which is otherwise likely to damage or destroy {{ appName }}'s reputation or the reputation of any third party.
						</li>

						<li>
							harming minors in any way.
						</li>

						<li>
							entering, publishing, transmitting material or any other content by e-mail or any other way it contains software viruses or other information, files or programmes designed or apt to interrupt, destroy or restrict the functioning of the computer software and hardware or of telecommunication systems.
						</li>

						<li>
							to engage in any form of harassment or offensive behaviour, including but not limited to the distribution of any sexually and / or racially offensive, abusive, threatening, vulgar, obscene, harassing, libellous, slanderous, or objectionable material of any kind, nor any unlawful or illegal material, or any material that infringes or violates another party's rights (including, but not limited to, intellectual property rights and privacy rights).
						</li>
					</ol>

					You acknowledge that {{ appName }} is not responsible for, and does not control or endorse any content of any Public Communication Services. If any information provided by you is untrue, inaccurate, nor current or incomplete, {{ appName }} has the right to modify your information or terminate your account and refuse any and all current or future use of the Services or access to the Sites.
				</li>

				<li>
					{{ appName }}'s Rights:

					<ol>
						<li>
							{{ appName }} reserves the right to make any alteration or changes to the service, or any part thereof, or suspend the Service or any part thereof without prior notice and {{ appName }} shall not be liable for any loss or inconvenience to you resulting therefrom.
						</li>

						<li>
							{{ appName }} reserves the right at its absolute discretion, from time to time, to vary, add to or otherwise amend these Terms and Conditions or any part thereof. Your continued use of the Service after the effective date of any variation, addition or amendments to these Terms and Conditions shall constitute our unconditional acceptance of such variations, additions or amendment.
						</li>

						<li>
							Unless otherwise notified by you, you agree that {{ appName }} may send you notifications relating to promotional and marketing activities from time to time.
						</li>

						<li>
							{{ appName }} may extract any details or Personal Information or any other data from you which is required to be used as evidence in court and / or when necessary in the event of a suspected and or proven misuse of the service.
						</li>
					</ol>
				</li>

				<li>
					Charges:

					<ol>
						<li>
							{{ appName }} reserves the right to impose any charges, fees or subscriptions ("Charges") for access to the Sites or for the use of the Services. Some Services may be chargeable as indicated on the Sites and in any accompanying Terms and Conditions.
						</li>

						<li>
							You will need to provide all equipment necessary to access the site on the Internet, and be liable for payment for your local telephone call charges at the rates published by the telephone operator with whom you make your local calls, or any other internet access charges to which you may be subjected to. If your equipment does not support relevant technology including but not limited to encryption, you may not be able to use certain Services or access certain information of the Sites.
						</li>
					</ol>
				</li>

				<li>
					{{ appName }}'s liability:

					<p></p>

					{{ appName }} shall not be liable for any costs, loss or damages (whether direct or indirect) or for loss of revenue, loss of profits or any consequential loss whatsoever as a result of your usage of the service.
				</li>

				<li>
					Liability of Content:

					<ol>
						<li>
							You acknowledge that {{ appName }} is under no obligation to censor the content or information provided.
						</li>

						<li>
							It is your sole responsibility to satisfy yourself prior to using the {{ appName }} service in any way that they are suitable for your purposes and up to date. The services and in particular, Charges are periodically updated and you should check the {{ appName }} sites regularly to ensure that you have the latest information. You should also refresh your browser each time you visit the Sites and the Services, to ensure that you download the most up to date version of the site.
						</li>

						<li>
							{{ appName }} sites are provided on an "as is" basis. Although every effort has been made to provide accurate information on these pages, neither {{ appName }}, nor any of its employees, their suppliers, employees, make any warranty, expressed or implied, or assume any legal liability (to the extent permitted by law), or responsibility for the suitability, reliability, timeliness, accuracy or completeness of the {{ appName }} service or any part thereof contained on the sites.
						</li>
					</ol>
				</li>

				<li>
					Disclaimer of Service:

					<p></p>

					This Service is provided on an "as is" and "as available" basis. {{ appName }} disclaims all liability and makes no express or implied representation or warranties of any kind in relation to the Service including but not limited to:

					<ol>
						<li>
							availability, accessibility, timeliness and uninterrupted use of the Service and
						</li>

						<li>
							sequence, accuracy, completeness, timeliness or the security of any data, content or information provided to you as part of the Service.
						</li>
					</ol>
				</li>
			</ol>
		</section>
	</div>
</template>

<script>
export default {
	name: 'terms',
	inject: ['$util'],
	data () {
		return {
			appName: this.$i18n.t('brand_name')
		}
	},
	activated () {
		this.$util.scrollToTop()
	}
}
</script>

<style lang="scss" scoped>
section {
	background-color: $color-background;

	/deep/ .app-link {
		display: inline-block;
		color: $color-accent-turquoise !important;
		text-decoration: underline !important;
	}
}

.info-list {
	text-align: justify;

	li {
		margin-left: $pd;
		padding-left: $pd / 2;
	}

	& > li {
		margin-bottom: $pd;
		list-style-type: decimal;

		& > ol {

			& > li {
				margin-bottom: $pd / 2;
				list-style-type: lower-alpha;

				&:first-of-type { margin-top: $pd }
				&:last-of-type { margin-bottom: $pd }
			}
		}
	}
}
</style>
